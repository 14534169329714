import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import App from '../components/App'

const IndexPage = ({ data }) => {
  const {
    allMarkdownRemark: { edges },
  } = data

  return (
    <>
      <Layout>
        <div id="fv">
          <h1>
            社会を取り巻くリスクを
            <br />
            アルゴリズムで解決する
          </h1>

          <div className="about-comp">
            <Link to={'/#about'}>
              <span className="line"></span>About Company
            </Link>
          </div>
          {/* /.about-comp */}

          <App />
        </div>
        {/* /#fv */}

        <section id="about">
          <div className="v-line"></div>

          <h2>About</h2>

          <div className="img-wrapper">
            <img
              className="pksha"
              src="/assets/images/about-pksha.png"
              alt=""
            />
            <img
              className="t-marine"
              src="/assets/images/about-tokiomarine.png"
              alt=""
            />
          </div>
          {/* /.img-wrapper */}

          <p>
            AlgoNautは、東京海上ホールディングスと
            <br className="sp-only" />
            PKSHA Technologyが両社の経営資源を
            <br className="sp-only" />
            最大限に生かすために設立した合弁会社です。
            <br />
            東京海上グループ各社が積み上げてきた
            <br className="sp-only" />
            リスクデータやリスク予防ノウハウと、
            <br />
            PKSHAが自社開発した機械学習・深層学習領域の
            <br className="sp-only" />
            アルゴリズムを用いて
            <br className="pc-only" />
            お客様や社会を取り巻く
            <br className="sp-only" />
            リスクに対応するソリューション事業を
            <br className="sp-only" />
            企画・開発してまいります。
          </p>

          <div className="img-wrapper-sp">
            <div className="inner">
              <img
                className="t-marine"
                src="/assets/images/about-tokiomarine.png"
                alt=""
              />
              <img
                className="pksha"
                src="/assets/images/about-pksha.png"
                alt=""
              />
            </div>
            {/* /.inner */}
          </div>
          {/* /.img-wrapper */}

          <div className="thought">
            <h3>社名に込めた想い</h3>
            <p>
              「AlgoNaut（アルゴノート）」は、ギリシア神話に登場するアルゴー船に乗り込む英雄達を表すArgonautとAlgorithmを組み合わせた造語です。「アルゴリズムを活用することによって、これまでにない新たなビジネスを機動的に創出する企業」との想いを込めており、社会課題を解決する最先端のデジタルリスクソリューションの創出に挑戦してまいります。
            </p>
          </div>
          {/* /.thought */}
        </section>
        {/* /#about */}

        <section id="news">
          <div className="inner">
            <h2>News</h2>
            <div className="news-wrapper">
              <p className="date">2023.12.15</p>
              <p className="title content">
                <a
                  href="https://www.pkshatech.com/news/20231215/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PKSHAグループと内田洋行、こども家庭庁の実証事業として埼玉県戸田市のこどもの不登校をAIで予測する取組みに参画
                </a>
              </p>
            </div>
            <div className="news-wrapper">
              <p className="date">2023.12.14</p>
              <p className="title content">
                <a
                  href="https://www.nikkei.com/article/DGXZQOUC0913J0Z01C23A2000000/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AIによる不登校予測に関する取組について日経新聞に掲載されました。
                </a>
              </p>
            </div>
            <div className="news-wrapper">
              <p className="date">2023.8.2</p>
              <p className="title content">
                <a
                  href="https://www.pkshatech.com/news/2023080201/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PKSHA、東京海上ホールディングス等と疾病予測アルゴリズムを共同開発
                </a>
              </p>
            </div>
            <div className="news-wrapper">
              <p className="date">2023.4.19</p>
              <p className="title content">
                <a
                  href="https://www.pkshatech.com/news/20230419/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PKSHA、東京海上日動と大規模言語モデルを活用した保険業界特化の対話型AIの開発に着手
                  ーAzure OpenAI Service、PKSHA LLMSを活用
                </a>
              </p>
            </div>
            <div className="news-wrapper">
              <p className="date">2022.9.13</p>
              <p className="title content">
                <a
                  href="https://www.pkshatech.com/news/2022091301/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  日新火災海上保険に不正請求検知アルゴリズム「PKSHA Security for
                  Insurance」を提供開始
                </a>
              </p>
            </div>
            <div className="news-wrapper">
              <p className="date">2022.4.20</p>
              <p className="title content">
                <a
                  href="https://www.pkshatech.com/news/2022-04-20T00:00:00.000Z/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  「防災コンソーシアム（CORE）」が本格始動
                </a>
              </p>
            </div>
            <div className="news-wrapper">
              <p className="date">2021.11.24</p>
              <p className="title content">
                <a
                  href="/assets/images/news/20211124.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  災害に負けない強靭な社会を目指し「防災コンソーシアム（CORE）」を発足
                </a>
              </p>
            </div>

            {edges.map((aNews) => (
              <div key={aNews.node.id} className="news-wrapper">
                <p className="date">{aNews.node.frontmatter.date}</p>
                <p className="title content">{aNews.node.frontmatter.title}</p>
              </div>
            ))}
          </div>
          {/* /.inner */}
        </section>
        {/* /#news */}

        <section id="company" className="clearfix">
          <h2 className="sp-only">Company</h2>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.804701835699!2d139.75539241525954!3d35.70642328018844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c3be1fd5717%3A0x815903d84cc014a3!2z44CSMTEzLTAwMzMg5p2x5Lqs6YO95paH5Lqs5Yy65pys6YO377yS5LiB55uu77yT77yV4oiS77yR77yQIOacrOmDt-eArOW3neODk-ODqw!5e0!3m2!1sja!2sjp!4v1628752246015!5m2!1sja!2sjp"
            title="company map"
            width="800"
            height="600"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>

          <div className="overview">
            <div className="inner">
              <h2 className="pc-only">Company</h2>
              <table>
                <tr>
                  <th>社名</th>
                  <td>株式会社AlgoNaut（アルゴノート）</td>
                </tr>
                <tr>
                  <th>所在地</th>
                  <td>東京都文京区本郷 2-35-10 本郷瀬川ビル 4 階</td>
                </tr>
                <tr>
                  <th>代表者</th>
                  <td>代表取締役 佐野 長紀</td>
                </tr>
                <tr>
                  <th>資本金</th>
                  <td>300 百万円（資本準備金含む）</td>
                </tr>
                <tr>
                  <th>出資比率</th>
                  <td>PKSHA 60%、東京海上 HD 40%</td>
                </tr>
                <tr>
                  <th>事業内容</th>
                  <td>アルゴリズムライセンス事業</td>
                </tr>
                <tr>
                  <th>設立日</th>
                  <td>2020 年 11 月 9 日</td>
                </tr>
              </table>
            </div>
            {/* /.inner */}
          </div>
          {/* /.overview */}
        </section>
        {/* /#company */}
      </Layout>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query NewsIndexQuery {
    site {
      siteMetadata {
        toppageTitle
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "news-template" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
      skip: 0
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY.MM.DD")
          }
        }
      }
    }
  }
`
